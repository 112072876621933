<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <a-range-picker v-model="form.dateVal" @change="onChangeDate" style="width: 210px;" class="margin_right20"/>
                        <a-select allowClear v-model="form.courseName" show-search placeholder="课程/商品关键字" style="width: 210px"  class="margin_right20" :default-active-first-option="false" :show-arrow="false" :filter-option="false" :not-found-content="null" :options="options" @search="handleSearch"></a-select>
                        <a-button type="primary" class="margin_right20" @click="resetClick">重置</a-button>
                        <a-button type="primary" class="margin_right20" @click="init">导出</a-button>
                    </div>
                </div>
            </div>
            <div style="margin-top: 220px">
                <a-empty description="选择时间可导出数据" />
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import axios from "axios";
import {domainName} from "@/config/index";
import {SearchCourse} from "@/request/api/userManage";
export default {
    data() {
        return {
            spinning: false,
            tableLoading: false,
            isDateShow:true,
            form:{
                courseName:undefined,
                startTime:'',
                endTime:'',
                dateVal:[],
            },
            options:[],
            sdtime:'',
        };
    },
    mounted() {
    
    },
    methods: {
        handleSearch(val){
            if(!val){
                this.options = []
            }else{
                SearchCourse({
                    word: val,
                }).then((res) => {
                    if (res.code === 200) {
                        this.options = []
                        let options = res.data
                        for(let i = 0; i < options.length; i++){
                            let obj = {}
                            obj.label = options[i].name
                            obj.value = options[i].name
                            this.options.push(obj)
                        }
                    }
                });
            }
        },
        
        // 点击重置
        resetClick(){
            this.form.courseName = ''
            this.form.startTime = ''
            this.form.endTime = ''
            this.form.dateVal = []
            this.isDateShow = false
        },
        
        init(){
            if(!this.form.startTime){
                this.$message.warning('请选择时间！')
                return false
            }
            
            let token = localStorage.getItem("token");
            this.tableLoading = true
            let urlLink = domainName
            if(urlLink != "https://test.shenyiedu.com/"){
                urlLink = 'https://admin.shenyiedu.com/'
            }
            let url = `${urlLink}/adminv2/usercenter/dailyOrder`
            let obj = {
                courseName: !this.form.courseName ? '' : this.form.courseName,
                startTime: this.form.startTime,
                endTime: this.form.endTime,
            }
            axios.post(url,obj,{
                responseType: 'blob',
                headers:{
                    'accesstoken':token,
                    'authorization':token
                }
            }).then((res) => {
                this.tableLoading = false
                let blob = res.data;
                const link = document.createElement('a');
                blob = new Blob([blob], {type: 'application/x-excel'});
                link.style.display = 'none';
                link.href = URL.createObjectURL(blob);
                link.download = "各平台销售日报统计.xls";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        },
        
        // 搜索 - 修改时间
        onChangeDate(date, dateString) {
            if (dateString[0] != "") {
                this.form.startTime = dateString[0];
                this.form.endTime = dateString[1];
            } else {
                this.form.startTime = "";
                this.form.endTime = "";
            }
        },
    }
};
</script>

<style lang="less" scoped>
.table-operator{
    display: flex;
}
</style>
