<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <treeselect @treeDataChange="treeDataChange" :isDateShow="isDateShow" style="width: 250px;" class="margin_right20"></treeselect>
                        <a-input v-model="form.phone" style="width:210px;" placeholder="推广人手机号" class="margin_right20"/>
                        <a-range-picker v-model="form.onChangeRegisterVal" @change="onChangeRegister" :placeholder="['注册开始时间', '注册结束时间']" style="width: 250px;" class="margin_right20"/>
                        <a-range-picker v-model="form.onChangePaymentVal" @change="onChangePayment" :placeholder="['支付开始时间', '支付结束时间']" style="width: 250px;" class="margin_right20"/>
                        <a-button type="primary" @click="queryClick" class="margin_right20">查询</a-button>
                        <a-button type="primary" @click="resetClick" class="margin_right20">重置</a-button>
                        <a-button type="primary" @click="exportClick">导出</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.phone"  :loading="tableLoading" :pagination="false" :scroll="{x: 2100}">
                        <template slot="totalGmv" slot-scope="text, record">
                            <span>{{ record.totalGmv.toFixed(2) }}</span>
                        </template>
                        <template slot="totalMemberGmv" slot-scope="text, record">
                            <span>{{ record.totalMemberGmv.toFixed(2) }}</span>
                        </template>
                        <template slot="totalCourseGmv" slot-scope="text, record">
                            <span>{{ record.totalCourseGmv.toFixed(2) }}</span>
                        </template>
                        <template slot="totalShareUserNum" slot-scope="text, record">
                            <span>{{ record.totalShareUserNum }} <b style="color: #00a0e9">|</b> {{ record.totalShareOrderNum }}</span>
                        </template>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            </div>
        </a-spin>
    
        <!--    导出弹框    -->
        <a-modal v-model="visibleShow" title="导出">
            <template #footer>
                <div style="display: flex;justify-content: flex-end">
                    <a-button @click="visibleShow = false" class="margin_right20">取消</a-button>
                    <download-excel
                        :data="exportList"
                        :fields="tableDataName"
                        name="阅读大使推广业绩汇总.xls">
                        <a-button type="primary" @click="visibleShow = false">确定</a-button>
                    </download-excel>
                </div>
            </template>
            <p>
                <a-icon type="info-circle" :style="{fontSize: '16px', color: '#FF8200'}"/>
                <span style="margin-left: 10px">确定要导出此数据吗？</span>
            </p>
        </a-modal>
        
        <router-view/>
        
        
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import Treeselect from "@/components/channel/treeselect";
import {AgentData} from "@/request/api/saleManage";
import {codeFn} from "@/utils/tools";
export default {
    components: {MyPagination,Treeselect},
    data() {
        return {
            isShowExport:false,
            visibleShow:false,
            spinning: false,
            tableLoading: false,
            isDateShow:true,
            form:{
                phone:'',
                channel:[],
                onChangeRegisterVal:[],
                onChangePaymentVal:[],
                registerStartTime:'',
                registerEndTime:'',
                payStartTime:'',
                payEndTime:'',
            },

            columns: [
                {
                    title: "代理商姓名",
                    dataIndex: "agentName",
                    key: "agentName",
                    fixed: 'left',
                },
                {
                    title: "代理商手机号",
                    dataIndex: "phone",
                    key: "phone",
                },
                {
                    title: "父级代理姓名",
                    dataIndex: "parentAgent",
                    key: "parentAgent",
                },
                {
                    title: "最上级代理姓名",
                    dataIndex: "grandAgent",
                    key: "grandAgent",
                },
                {
                    title: "总推广人数 | 总订单数",
                    dataIndex: "totalShareUserNum",
                    key: "totalShareUserNum",
                    scopedSlots: {customRender: "totalShareUserNum"},
                },
                {
                    title: "总推广GMV",
                    dataIndex: "totalGmv",
                    key: "totalGmv",
                    scopedSlots: {customRender: "totalGmv"},
                    sorter: (a, b) => {
                        let aTotalGmv = a.totalGmv;
                        let bTotalGmv = b.totalGmv;
                        return aTotalGmv - bTotalGmv;
                    },
                },
                {
                    title: "会期商品订单数",
                    dataIndex: "memberShareOrderNum",
                    key: "memberShareOrderNum",
                },
                {
                    title: "会期商品推广GMV",
                    dataIndex: "totalMemberGmv",
                    key: "totalMemberGmv",
                    scopedSlots: {customRender: "totalMemberGmv"},
                    sorter: (a, b) => {
                        let aTotalMemberGmv = a.totalMemberGmv;
                        let bTotalMemberGmv = b.totalMemberGmv;
                        return aTotalMemberGmv - bTotalMemberGmv;
                    },
                },
                {
                    title: "非会期商品订单数",
                    dataIndex: "courseShareOrderNum",
                    key: "courseShareOrderNum",
                },
                {
                    title: "非会期商品推广GMV",
                    dataIndex: "totalCourseGmv",
                    key: "totalCourseGmv",
                    fixed: 'right',
                    scopedSlots: {customRender: "totalCourseGmv"},
                    sorter: (a, b) => {
                        let aTotalCourseGmv = a.totalCourseGmv;
                        let bTotalCourseGmv = b.totalCourseGmv;
                        return aTotalCourseGmv - bTotalCourseGmv;
                    },
                }
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数
    
            exportList:[],
            tableDataName: {
                "代理商姓名": "agentName",
                "代理商手机号": "phone",
                "父级代理姓名": "parentAgent",
                "最上级代理姓名": "grandAgent",
                "总推广人数": "totalShareUserNum",
                "总推广人数总订单数": "totalShareOrderNum",
                "总推广GMV": {
                    field: "totalGmv",
                    callback: value => {
                        return value.toFixed(2)
                    }
                },
                "会期商品订单数": "memberShareOrderNum",
                "会期商品推广GMV": {
                    field: "totalMemberGmv",
                    callback: value => {
                        return value.toFixed(2)
                    }
                },
                "非会期商品订单数": "courseShareOrderNum",
                "非会期商品推广GMV": {
                    field: "totalCourseGmv",
                    callback: value => {
                        return value.toFixed(2)
                    }
                },
            },
        };
    },
    created() {
        if (codeFn("/adminv2/usercenter/agentData")) this.isShowExport = true;
    },
    mounted() {
        this.getTagList();
    },
    methods: {

        // 搜索 · 点击搜索按钮
        queryClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.getTagList()
        },
        
        // 点击导出
        exportClick(){
            AgentData({
                parentNos:this.form.channel.toString(),
                phone:this.form.phone,
                registerStartTime:this.form.registerStartTime,
                registerEndTime:this.form.registerEndTime,
                payStartTime:this.form.payStartTime,
                payEndTime:this.form.payEndTime,
                pageNo: this.pageNo,
                pageSize: 0,
            }).then((res) => {
                if (res.code === 200) {
                    if(res.data.list.length>0){
                        this.exportList = res.data.list;
                        this.visibleShow = true
                    }else{
                        this.$message.warning('暂无可导出数据')
                    }
                }
            });
        },

        // 点击重置按钮
        resetClick(){
            this.form.phone = ''
            this.form.channel = []
            this.form.onChangeRegisterVal = []
            this.form.onChangePaymentVal = []
            this.form.registerStartTime = ''
            this.form.registerEndTime = ''
            this.form.payStartTime = ''
            this.form.payEndTime = ''
            this.isDateShow = false
            this.getTagList()
        },

        // 搜索·用户归属
        treeDataChange(data){
            this.form.channel = data
            this.isDateShow = true
        },

        // 搜索 - 注册时间
        onChangeRegister(date, dateString) {
            if (dateString[0] != "") {
                this.form.registerStartTime = dateString[0];
                this.form.registerEndTime = dateString[1];
            } else {
                this.form.registerStartTime = "";
                this.form.registerEndTime = "";
            }
        },

        // 搜索 - 支付时间
        onChangePayment(date, dateString){
            if (dateString[0] != "") {
                this.form.payStartTime = dateString[0];
                this.form.payEndTime = dateString[1];
            } else {
                this.form.payStartTime = "";
                this.form.payEndTime = "";
            }
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            AgentData({
                parentNos:this.form.channel.toString(),
                phone:this.form.phone,
                registerStartTime:this.form.registerStartTime,
                registerEndTime:this.form.registerEndTime,
                payStartTime:this.form.payStartTime,
                payEndTime:this.form.payEndTime,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
    }
};
</script>

<style lang="less" scoped>
.table-operator{
    display: flex;
}
/deep/ .ant-input{
    height: 36px !important;
}
/deep/ .ant-select-selection--single{
    height: 36px !important;
}
</style>
