// 销售中心
import request from '@/request/request'

// 渠道销售数据汇总
export const AgentSales = (params) => request.post(`/adminv2/usercenter/agentSales`, params);

// 用户推广业绩汇总
export const UserShareData = (params) => request.post(`/adminv2/usercenter/userShareData`, params);

// 代理商推广数据汇总
export const AgentData = (params) => request.post(`/adminv2/usercenter/agentData`, params);

// 各平台销售日报统计
export const DailyOrder = (params) => request.post(`/adminv2/usercenter/dailyOrder`, params);
