<template>
    <div class="ant-card" id="course_category">
        <a-spin v-if="$route.meta.isShow" :spinning="spinning" size="large">
            <div class="ant-card-body">
                <div class="head-box">
                    <div class="table-operator">
                        <a-input v-model="form.phone" style="width:210px;" placeholder="推广人手机号" class="margin_right20"/>
                        <a-range-picker v-model="form.onChangeRegisterVal" @change="onChangeRegister" :placeholder="['注册开始时间', '注册结束时间']" style="width: 250px;" class="margin_right20"/>
                        <a-range-picker v-model="form.onChangePaymentVal" @change="onChangePayment" :placeholder="['支付开始时间', '支付结束时间']" style="width: 250px;" class="margin_right20"/>
                        <a-button type="primary" @click="queryClick" class="margin_right20">查询</a-button>
                        <a-button type="primary" @click="resetClick">重置</a-button>
                    </div>
                </div>
                <div class="contant-box">
                    <a-table ref="table" :columns="columns" :data-source="tableList" :row-key="record => record.UserName"  :loading="tableLoading" :pagination="false" :scroll="{x: 2100}">
                        <template slot="isAgent" slot-scope="text, record">
                            <span>{{ record.isAgent == 1 ? '是' : '否' }}</span>
                        </template>
                        <template slot="totalGmv" slot-scope="text, record">
                            <span>{{ record.totalGmv.toFixed(2) }}</span>
                        </template>
                        <template slot="totalMemberGmv" slot-scope="text, record">
                            <span>{{ record.totalMemberGmv.toFixed(2) }}</span>
                        </template>
                        <template slot="totalCourseGmv" slot-scope="text, record">
                            <span>{{ record.totalCourseGmv.toFixed(2) }}</span>
                        </template>
                        <template slot="totalShareUserNum" slot-scope="text, record">
                            <span>{{ record.totalShareUserNum }} <b style="color: #00a0e9">|</b> {{ record.totalShareOrderNum }}</span>
                        </template>
                        <template slot="memberShareUserNum" slot-scope="text, record">
                            <span>{{ record.memberShareUserNum }} <b style="color: #00a0e9">|</b> {{ record.memberShareOrderNum }}</span>
                        </template>
                        <template slot="courseShareUserNum" slot-scope="text, record">
                            <span>{{ record.courseShareUserNum }} <b style="color: #00a0e9">|</b> {{ record.courseShareOrderNum }}</span>
                        </template>
                    </a-table>
                </div>
                <MyPagination :count="count" :pageNo="pageNo" @showSizeChangeFn="showSizeChangeFn" v-show="!tableLoading"/>
            </div>
        </a-spin>
        <router-view/>
    </div>
</template>

<script>
import MyPagination from "@/components/pagination/MyPagination";
import {UserShareData} from "@/request/api/saleManage";
export default {
    components: {MyPagination},
    data() {
        return {
            spinning: false,
            tableLoading: false,

            form:{
                phone:'',
                onChangeRegisterVal:[],
                onChangePaymentVal:[],
                registerStartTime:'',
                registerEndTime:'',
                payStartTime:'',
                payEndTime:'',
            },

            columns: [
                {
                    title: "推广人姓名",
                    dataIndex: "nickName",
                    key: "nickName",
                    fixed: 'left',
                },
                {
                    title: "推广人手机号",
                    dataIndex: "userName",
                    key: "userName",
                },
                {
                    title: "推广人所属渠道",
                    dataIndex: "belongAgent",
                    key: "belongAgent",
                },
                {
                    title: "是否为阅读大使",
                    dataIndex: "isAgent",
                    key: "isAgent",
                    scopedSlots: {customRender: "isAgent"},
                },
                {
                    title: "总推广人数 | 总订单数",
                    dataIndex: "totalShareUserNum",
                    key: "totalShareUserNum",
                    scopedSlots: {customRender: "totalShareUserNum"},
                },
                {
                    title: "总推广GMV",
                    dataIndex: "totalGmv",
                    key: "totalGmv",
                    scopedSlots: {customRender: "totalGmv"},
                    sorter: (a, b) => {
                        let aTotalGmv = a.totalGmv;
                        let bTotalGmv = b.totalGmv;
                        return aTotalGmv - bTotalGmv;
                    },
                },
                {
                    title: "会期商品推广人数|订单数",
                    dataIndex: "memberShareUserNum",
                    key: "memberShareUserNum",
                    scopedSlots: {customRender: "memberShareUserNum"},
                },
                {
                    title: "会期商品推广GMV",
                    dataIndex: "totalMemberGmv",
                    key: "totalMemberGmv",
                    scopedSlots: {customRender: "totalMemberGmv"},
                    sorter: (a, b) => {
                        let aTotalMemberGmv = a.totalMemberGmv;
                        let bTotalMemberGmv = b.totalMemberGmv;
                        return aTotalMemberGmv - bTotalMemberGmv;
                    },
                },
                {
                    title: "非会期商品推广人数｜订单数",
                    dataIndex: "courseShareUserNum",
                    key: "courseShareUserNum",
                    width: 250,
                    scopedSlots: {customRender: "courseShareUserNum"},
                },
                {
                    title: "非会期商品推广GMV",
                    dataIndex: "totalCourseGmv",
                    key: "totalCourseGmv",
                    fixed: 'right',
                    scopedSlots: {customRender: "totalCourseGmv"},
                    sorter: (a, b) => {
                        let aTotalCourseGmv = a.totalCourseGmv;
                        let bTotalCourseGmv = b.totalCourseGmv;
                        return aTotalCourseGmv - bTotalCourseGmv;
                    },
                }
            ],
            tableList: [],

            pageNo: 1, // 当前页
            pageSize: 20, // 每页条数
            count: 0, // 列表的总条数
        };
    },
    mounted() {
        this.getTagList();
    },
    methods: {

        // 搜索 · 点击搜索按钮
        queryClick(){
            this.pageNo = 1;
            this.pageSize = this.pageSize;
            this.getTagList()
        },

        // 点击重置按钮
        resetClick(){
            this.form.phone = ''
            this.form.onChangeRegisterVal = []
            this.form.onChangePaymentVal = []
            this.form.registerStartTime = ''
            this.form.registerEndTime = ''
            this.form.payStartTime = ''
            this.form.payEndTime = ''
            this.getTagList()
        },

        // 搜索 - 注册时间
        onChangeRegister(date, dateString) {
            if (dateString[0] != "") {
                this.form.registerStartTime = dateString[0];
                this.form.registerEndTime = dateString[1];
            } else {
                this.form.registerStartTime = "";
                this.form.registerEndTime = "";
            }
        },

        // 搜索 - 支付时间
        onChangePayment(date, dateString){
            if (dateString[0] != "") {
                this.form.payStartTime = dateString[0];
                this.form.payEndTime = dateString[1];
            } else {
                this.form.payStartTime = "";
                this.form.payEndTime = "";
            }
        },

        // 分页功能切换的回调
        showSizeChangeFn(current, pageSize) {
            this.pageNo = current;
            this.pageSize = pageSize;
            this.getTagList();
        },

        // 列表数据
        getTagList() {
            this.tableLoading = true;
            UserShareData({
                phone:this.form.phone,
                registerStartTime:this.form.registerStartTime,
                registerEndTime:this.form.registerEndTime,
                payStartTime:this.form.payStartTime,
                payEndTime:this.form.payEndTime,
                pageNo: this.pageNo,
                pageSize: this.pageSize
            }).then((res) => {
                if (res.code === 200) {
                    this.tableList = res.data.list;
                    this.count = res.data.count;
                }
                this.tableLoading = false;
            });
        },
    }
};
</script>

<style lang="less" scoped>
.table-operator{
    display: flex;
}
</style>
